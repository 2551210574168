@font-face {
    font-family: 'Horizon';
    src: url('./fonts/Horizon-updated/NEWUPDATEDVERSION/horizon.otf') format('opentype');
  }


  body {

    /* Ensure the background covers the full viewport */
    background-size: cover;
    background-position: center center;
    background-attachment: fixed; /* Makes the image stay in place when scrolling */
    
    /* Set the background color to a solid color as fallback */
    background-color: #fff;  
    /* Other general body styles 
*/

font-family: 'Montserrat', sans-serif !important;

    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625;
    letter-spacing: 0.00938em;
    background-color: #16324F;
}

.blue{
  background-color: #1e6c97 !important;
  color: white !important;
}

.orange{
  background-color: #FF8A41 !important;
  color: white !important;
}
.ef-btn, .ef-title, .vendorTitle{
    font-family: 'Horizon' !important;

}

.ef-sm-btn{
    
    color: #fff !important; 
   background-color: #1e6c97 !important;  
}

.inverted{
  background-color: #fff !important; 
  color: #1e6c97 !important;  
}
/* ef-btn Class */
.ef-btn {
    border-radius: 1rem; /* Rounded corners */
    text-align: center; /* Center text */
    padding: 12px 20px; /* Adjusted padding */
    margin: 10px auto; /* Margin for spacing */
    width: 50%; /* Button width */
    height: 60px; /* Fixed height */
    display: block; /* Block level display for center alignment */
    color: #fff !important; /* White text */
    background-color: #1e6c97; /* Button background color */
    font-size: 1.1em; /* Larger text */
    font-weight: bold; /* Bold font */
    text-decoration: none; /* Remove underline */
    transition: all 0.3s ease-in-out; /* Smooth transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow */
  }
  
  /* Hover effect to scale the button */
  .ef-btn:hover {
    transform: scale(1.1); /* Enlarge button when hovered */
  }
  
  /* Optional: Active state to make the button feel "pressed" */
  .ef-btn:active {
    transform: scale(1); /* Reset to normal size when clicked */
  }
  
  Button{
   /*  color: #fff !important; /* White text
   background-color: #1e6c97 !important;  Button background color */
  }

  .delete{
    color: #fff !important; /* White text */
    background-color: red !important; /* Button background color */
  }

h1, h2, h3, h4 {
   /*
     font-family: 'Montserrat', sans-serif !important;*/
     font-family: 'Horizon' !important;

    font-weight: 600;
  }
  
Typography{
    font-family: 'Lora', serif !important;

}
  /* Body Text Styles (Lora) */
  body, p, li {
    line-height: 1.6;
    font-weight: 400;
  }
  
  
.italic{
    font-style: italic;
}

.title{
    font-family: 'Lora', serif !important;

}
.navbar{
    color: white !important;
}

.signIn{
  background-color: #1e6c97 !important;
  font-size: 1.5em !important;
  color: #fff !important;

}

.ef-cta-btn{
font-size: 1em;
padding: .5em;
font-family: 'Horizon', 'serif';
border-radius: 8px;
}

.ef-cta-btn:hover{
  transform: scale(1.1); /* Enlarge button when hovered */
  background-color: #FF8A41 !important;
     
     }

.vendorTitle{
/* color: #F2F2F2; */
text-align: left !important;
    font-size: 2.8em;
}

/* Mobile screen adjustments */
@media (max-width: 700px) {
    .vendorTitle {
      font-size: 1.5em; /* Font size for mobile screens */
      margin-left: -1em;
    }

    .ef-title{
      font-size: 1.25em;
    }

    .ef-cta-btn{
      font-size: .75em;
      padding: .5em;
      font-family: 'Horizon', 'serif';
      border-radius: 8px;
      }

      .ef-cta-btn:hover{
        transform: scale(1.1); /* Enlarge button when hovered */
        background-color: #FF8A41;
        
        }

  }

a{
    /* color: #2596BE !important; */
}
.small{
    font-size: .7em;
}

.profile-icons{
    font-size: 1.2em !important;
}

.footer{
    color: #E4FDE1 !important;
}